import React, { useState, useEffect } from "react";
import { PostCard } from ".";
import GhostContentAPI from "@tryghost/content-api";

function ReadNext({author, pageSlug, authorSlug}) {
    // console.log(authorSlug)
    const [results, setResults] = useState([]);
    

    let filteredAuthor = "authors:" + authorSlug;

    const api = new GhostContentAPI({
        url: "https://ghost.production.x-hub.net",
        key: "6d1d671d3d02a1516c22faaaaf",
        version: "v3",
    });

    const apiParams = api.posts.browse({
        limit: 4,
        include: "tags,authors",
        filter: filteredAuthor,
    });
    const apiPosts = () => {
        apiParams
            .then((posts) => {
                    setResults(posts)
            })
            .catch((err) => {
                console.log(err);
            });
    };
    useEffect(() => {
        try{
            apiPosts();}
            catch(e) {
                console.log("no connection", e)
            }
    }, []);
    return (
        <div className="py-20"> 
            {results.length > 1 ? <h1 className="general--h1-styles text-center">What to read next</h1> : <h1 className="general--h1-styles text-center"><span className="capitalize">{author}</span> has no other posts</h1>}
             <div className="grid lg:grid-cols-3 md:grid-cols-2 xl:gap-x-28 lg:gap-x-12 gap-x-14 gap-y-16 my-28">
                        {results.filter(result => result.slug != pageSlug).map((result) => (
                            <PostCard key={result.id} post={result} />
                        ))}
            </div>
        </div>
    )
}

export default ReadNext
