import { useState, useEffect } from "react";
import GhostContentAPI from "@tryghost/content-api";

const api = new GhostContentAPI({
    url: "https://ghost.production.x-hub.net",
    key: "6d1d671d3d02a1516c22faaaaf",
    version: "v3",
});

function usePosts(postsDataLimit, postsDataFilter, postsDataPageNumber) {
    const [postsDataResults, setPostsDataResults] = useState({
        meta: { pagination: { total: 0 } },
    });

    let filteredTag = "tags:" + postsDataFilter;

    const apiParams = api.posts.browse({
        limit: postsDataLimit,
        include: "tags,authors",
        filter: filteredTag,
        page: postsDataPageNumber,
    });

    useEffect(() => {
        apiParams
            .then((posts) => {
                setPostsDataResults(posts);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [postsDataLimit, postsDataFilter, postsDataPageNumber]);

    return { postsDataResults };
}

export default usePosts;
