import { useState, useEffect } from "react";
import GhostContentAPI from "@tryghost/content-api";

const api = new GhostContentAPI({
    url: "https://ghost.production.x-hub.net",
    key: "6d1d671d3d02a1516c22faaaaf",
    version: "v3",
});

function useTags(slugTag, postsDataPageNumber) {
    const [tagsDataResults, setTagsDataResults] = useState({ meta: { pagination: { total: 0 } },});

    let filteredTag = "slug:" + slugTag;

    const apiParams = api.tags.browse({
        filter: filteredTag,
        limit: 200,
        page: postsDataPageNumber,
    });

    useEffect(() => {
        apiParams
            .then((tags) => {
                setTagsDataResults(tags);
            })
            .catch((err) => {
                console.log(err);
            });
    }, [slugTag]);

    return { tagsDataResults } ;
}

export default useTags;
